import React from "react"
import { Link } from "gatsby"
import CognitoForm from '@tylermenezes/cognitoforms-react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSectionReferral from "../components/heroSection/heroSectionReferral";
import DescriptionSectionReferral from "../components/descriptionSection/descriptionSectionReferral";
import WorkflowReferral from "../components/workflow/wordflowReferral";

const BecomePartner = () => {
  return (
    <Layout title="Become A Partner">
      <SEO title="Become A Partner" />
      <HeroSectionReferral />
      <DescriptionSectionReferral />
      <WorkflowReferral />
      <div className="container" id="cognito-form" style={{marginBottom: '160px'}}>
        <CognitoForm
          formId={1}
          accountId={`S92oGu_Yd02-rQ6oPeUSqw`}
          css=".cognito .c-forms-form { 
            background: #fbfbfb; 
            width: 100%;
            max-width: 600px;
            margin: 0 auto; }
            .cognito form:not(.cog-form) {
              font-size: 22px;
            }
            .cognito .c-span-24 .c-span-12 {
              width: 100%;  
            }"
            />
        <p style={{textAlign: 'center'}}>By becoming a partner you agree to the partnership <Link to="/terms-and-conditions">Terms and Conditions</Link></p>
      </div>
    </Layout>
  )
}

export default BecomePartner
