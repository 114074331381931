import React, { useRef, useEffect } from 'react'
import { gsap, TimelineLite  } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import useViewport from "../useViewport"
import styles from "./descriptionSection.module.scss"
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger, TimelineLite);

const DescriptionSectionReferral = () => {
  const descriptionSection = useRef(null);
  const { width } = useViewport()
  
  useEffect(() => {
    const header = descriptionSection.current.children[0].querySelector(".underline-header");
    const headerLine = descriptionSection.current.children[0].querySelector(".animated-underline");

    let tl = new TimelineLite ({
      scrollTrigger: {
        trigger: header,
        toggleActions: 'play none none none',
        start: 'top 80%',
      },
      ease: "power4.out"
    });

    tl.to(headerLine, 2, {backgroundSize: "100% 2px"});
  })

  return (
    <section id="about" className={`${styles.descriptionSection} description-section`} ref={descriptionSection}>
      <div className="container">
        <div className={`${styles.descriptionSectionWrapper} description-section-wrapper`}>
          <div className={styles.descriptionSectionContent}>
            <h3 className={`${styles.underlineHeader} underline-header`}>
              <span className={`animated-underline`}>Don’t Just Say ‘No’ to Clients. Refer them to us and Make Money.</span>
            </h3>
            <p className={`${styles.description} description`}>As Hades Media partner, you will be able to refer clients you don’t have time to take on or simply don’t offer the service they’re asking for.</p>
            <p className={`${styles.description} description`}>Hades Media helps businesses of all sizes scale and reach the next level. Others call it Omni-Channel Marketing. We call it Marketing done by Hades.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DescriptionSectionReferral
