import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import useViewport from "../useViewport"
import styles from "./workflow.module.scss"
import 'swiper/swiper.scss';

import ContactButton from "../contactButton"

SwiperCore.use([Navigation, Pagination]);

const WorkflowReferral = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { width } = useViewport()

  const data = [
    {
      "title": "Refer a business.",
      "content": "Fill out the easy-to-use referral form. We'll let the business know that you're looking out for their business' best interests. Or simply send a group email."
    },
    {
      "title": "They sign up with us.",
      "content": "When your referral signs up, we'll email you with the good news."
    },
    {
      "title": "You get paid! 💸",
      "content": "After your referee signs the contract, we will pay you within 50 days. Additional $4,000 will look pretty good in your bank account."
    },
  ];
    
  const renderCustomBullets = (index, className) => {
    
    const startingZero = (index < 9) && '0'
    let number;
    
    if (index < data.length - 1) {
      number = `<span class="number ${styles.number}">s.${startingZero}${index+1}</span>`
    }

    if (index === data.length - 1) {
      number = `<span class="number ${styles.number}">💸</span>`
    }
    
    const title = `<h4 class="title">${data[index].title}</h4>`
    
    return (
      '<div class="' + className + ' item-bullet">' + number + title + '</div>'
    )
  }
      
  return (
    <section id="workflow" className={styles.workflow}>
      <div className={`container ${styles.container}`}>
        <div className={styles.sectionWrapper}>
          <div className={`${styles.mainDescription} ${styles.referralDescription}`}>
            <h2 className={`section-header ${styles.sectionHeader} ${styles.referralHeader}`}>How it works</h2>
          </div>
          {data &&
            <div className={`${styles.content} ${styles.referralContent} referral-content`}>
              <span id="pagination"></span>

              <Swiper
                slidesPerView={1}
                navigation
                pagination={{
                  el: '#pagination',
                  type: 'bullets',
                  clickable: true,
                  renderBullet: (index, className) => renderCustomBullets(index, className)
                }}
                onSlideChange={(swiper) => {setCurrentSlide(swiper.activeIndex); swiper.updateProgress()}}
                className={`${styles.slidesWrapper} ${styles.referralSlidesWrapper} ${(currentSlide >= data.length - 1) && styles.lastSlide + ' last-slide'}`}
              >
                {data.map((item, index) => (

                  <SwiperSlide key={index} className={styles.item}>                
                    <div className={styles.content}>
                      <span className={styles.number}>s.{index < 9 ? `0${index+1}` : index+1}</span>
                      <h3 className={styles.header}>{item.title}</h3>
                      <p className={styles.description}>{item.content}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default WorkflowReferral