import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';
import ContactButton from "../contactButton"
import moneyThrowingGIF from '../../images/throwing-money.webp'
import styles from "./heroSection.module.scss"

const HeroSectionReferral = () => {
  return (
    <section className={styles.heroSection}>
      <div className="container">
        <div className={styles.heroSectionWrapper}>
          <div className={`${styles.headImage} ${styles.babyImage}`}>
            <img src={moneyThrowingGIF} alt="Baby Throwing Money" />
          </div>
          <div className={styles.heroContent}>
            <div className={styles.siteTitle}><span className={styles.text}>Hades media</span><span className={styles.line}></span></div>
            <h1 className={`${styles.pageHeader}`}>Earn $4,000 For Each Successful Referral</h1>
            <p>Help us help small businesses overcome the biggest obstacles of scaling their ecommerce businesses. For every business that purchases our trial package, you’ll earn a $4,000 referral fee. That’s right, even if they don’t become a client post-trial, you will still get paid. Yes, it’s that simple.</p>
            <button className="btn outline" onClick={() => scrollTo('#cognito-form')}>Become a Partner</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSectionReferral
